<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :app="user !== null"
      v-show="user !== null"
    >
      <v-sheet
        v-show="user"
        color="primary"
        class="pa-4"
        dark
      >
      <v-row class="px-2">
        <v-avatar
          class="mb-4"
          color="grey darken-1"
          size="64"
        >
          <v-img
            contain
            :src="user ? user.photoURL : ''"
          />          
        </v-avatar>
        <div class="col">
          <div class="text-body-1 font-weight-bold" v-show="user">{{ user ? user.displayName : ''}}</div>
        </div>
      </v-row>
      <div class="text-center mb-1">
        <div class="text-caption mb-1" v-show="user">{{ user ? user.email : ''}}</div>
        <v-chip small v-if="returnStoreUser" @click="returnStoreUser.userRole == 'Department Head' ? changeUserDepartmentSection() : ''">{{returnStoreUser.department}} - {{returnStoreUser.userRole}}</v-chip>
      </div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, route, access] in links"
          :key="icon"
          link
          dense
          v-show="returnAccess(access)"
          :to="route"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="secondary"
      
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" v-show="user !== null"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Perahub Logo"
          class="shrink ml-2"
          :style="returnPath !== '' ? 'cursor: pointer;' : ''"
          contain
          src="@/assets/perahub-logo.png"
          transition="scale-transition"
          width="100"
          @click="returnPath !== '' ? $router.push('/') : ''"
        />

      </div>

      <v-spacer></v-spacer>

      <v-badge
        v-show="ActivityLogs  && returnStoreUser && returnStoreUser.userRole == 'Department Head'"
        :content="returnLengthNotSeenNotifs"
        :value="returnLengthNotSeenNotifs"
        color="primary"
        overlap
        small
        class="mr-2"
      >
      <v-menu
        bottom
        left
        :offset-y="true"
        :close-on-content-click="false"
        :nudge-width="200"
        v-show="ActivityLogs"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            dense
            small
            @click="openMenuNotifSeen()"
          >
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
        <v-card max-width="300">
        <v-list v-show="ActivityLogs" three-line>
          <v-subheader class="primary--text font-weight-bold"><v-icon small class="mr-2 primary--text">mdi-bell</v-icon> Activity Logs</v-subheader>
          <v-list-item link dense v-show="returnLogs" v-for="logs in returnLogs.slice(0,3)" :key="logs['.key']">
          <v-list-item-avatar>
            <v-img :src="logs.photoURL"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="logs.action"></v-list-item-title>
            <v-list-item-subtitle class="text-caption" v-html="logs.displayName"></v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption" v-show="logs.timestamp" >{{$moment(logs.timestamp.toDate()).fromNow()}}</v-list-item-subtitle>
          </v-list-item-content>
              <!-- <v-list-item-action>
                <v-list-item-action-text>{{$moment(logs.timestamp.toDate()).fromNow()}}</v-list-item-action-text>
              </v-list-item-action> -->
          </v-list-item>
          <v-btn color="primary" block text @click="$router.push('/activitylogs')">see more activity logs</v-btn>
        </v-list>
        </v-card>
      </v-menu>
      </v-badge>



      <v-badge
        v-show="ReportedRisk  && returnStoreUser && returnStoreUser.department == 'RISK MANAGEMENT TEAM'"
        :content="returnLengthNotSeen"
        :value="returnLengthNotSeen"
        color="primary"
        overlap
        small
        class="mr-2"
      >
      <v-menu
        bottom
        left
        :offset-y="true"
        :close-on-content-click="false"
        :nudge-width="200"
        v-show="ReportedRisk"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            dense
            small
          >
            <v-icon>mdi-alert</v-icon>
          </v-btn>
        </template>
        <v-card max-width="350">
        <v-list v-show="ReportedRisk" three-line>
          <v-subheader class="primary--text font-weight-bold"><v-icon small class="mr-2 primary--text">mdi-alert</v-icon> Reported Risk Alerts</v-subheader>
          <v-list-item link dense v-show="ReportedRisk" v-for="reports in ReportedRisk.slice(0,3)" :key="reports['.key']">
          <v-list-item-content>
            <v-list-item-title >{{reports.RiskName}}</v-list-item-title>
            <v-list-item-subtitle >{{reports.IncidentDetails}}</v-list-item-subtitle>
          </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{$moment(reports.timestamp.toDate()).fromNow()}}</v-list-item-action-text>
              </v-list-item-action>
          </v-list-item>
          <v-btn color="primary" block text @click="$router.push('/reportedrisk')">see more reports</v-btn>
        </v-list>
        </v-card>
      </v-menu>
      </v-badge>

      <v-badge
        v-show="RiskAlerts  && returnStoreUser && returnStoreUser.department !== 'RISK MANAGEMENT TEAM'"
        :content="returnLengthNotSeenAlert.length"
        :value="returnLengthNotSeenAlert.length"
        color="primary"
        overlap
        small
        class="mr-2"
      >
      <v-menu
        bottom
        left
        :offset-y="true"
        :close-on-content-click="false"
        :nudge-width="200"
        v-show="RiskAlerts"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            dense
            small
          >
            <v-icon>mdi-alert</v-icon>
          </v-btn>
        </template>
        <v-card max-width="350">
        <v-list v-show="RiskAlerts" three-line>
          <v-subheader class="primary--text font-weight-bold"><v-icon small class="mr-2 primary--text">mdi-alert</v-icon> Reported Risk Alerts</v-subheader>
          <v-list-item link dense v-show="RiskAlerts && returnLengthNotSeenAlert.length > 0" v-for="reports in returnLengthNotSeenAlert.slice(0,3)" :key="reports['.key']">
          <v-list-item-content>
            <v-list-item-title >{{reports.RiskName}}</v-list-item-title>
            <v-list-item-subtitle >{{reports.IncidentDetails}}</v-list-item-subtitle>
          </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{$moment(reports.timestamp.toDate()).fromNow()}}</v-list-item-action-text>
              </v-list-item-action>
          </v-list-item>
          <v-btn color="primary" block text @click="$router.push('/riskalerts')">see more reports</v-btn>
        </v-list>
        </v-card>
      </v-menu>
      </v-badge>

      <v-btn small icon @click="$router.push('/useraccounts-rmt')" v-show="user !== null && returnStoreUser && returnStoreUser.department == 'RISK MANAGEMENT TEAM'">
        <v-icon>mdi-card-account-details-outline</v-icon>
      </v-btn>

      <v-btn text small @click="signOut" v-show="user !== null">
        <span class="mr-2">Sign Out</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <div class="rtl mr-4" v-show="returnPath !== 'addnewrisk' && returnPath !== 'createormprow' && returnPath !== 'reportedrisk' && returnPath !== 'viewallkri' && returnPath !== 'riskreportingform'">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
        <v-btn fab fixed bottom color="primary" v-bind="attrs" v-on="on" @click="$router.push('/riskreportingform')">
          <v-icon>mdi-alert-plus</v-icon>
        </v-btn>
        </template>
        <span>Risk Reporting Tool</span>
      </v-tooltip>
    </div>

    <v-dialog
      v-model="reportDialog"
      persistent
      max-width="500"
    >
      <v-card class="py-2">
        <v-card-title class="headline">
          <v-icon class="mr-2">mdi-alert-plus</v-icon> Risk Reporting Tool
          <v-spacer></v-spacer>
          <v-btn icon @click="reportDialog = false,clearReport()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          What happen and you wanted to report it as a risk to a company ?
          <v-select
            :items="mapRisk"
            v-model="newReport.riskType"
            label="Risk Type"
            filled
            class="mt-4"
          ></v-select>
          <v-textarea
            label="Tell us what happen?"
            v-model="newReport.description"
            filled
          ></v-textarea>
        <v-expansion-panels v-show="newReport.riskType" accordion class="elevation-0">
          <v-expansion-panel v-show="newReport.riskType" class="elevation-0">
            <v-expansion-panel-header class="elevation-0">
              Risk Information Guide
            </v-expansion-panel-header>
            <v-expansion-panel-content class="elevation-0">
            <div v-if="newReport.riskType !== null" class="pa-4 mb-4 text-caption grey lighten-4">
              <span v-show="newReport.riskType">
                <b>Risk Classification:</b> {{newReport.riskType.RiskClassification.RiskClassification}}
              </span>
              <br>
                <b>Universal Causes:</b>
              <br>
              <span v-show="newReport.riskType" v-for="(causes,cidx) in newReport.riskType.UniversalCauses" :key="cidx+$lodash.uniqueId()">
                {{causes}} <br>
              </span>
              <br>
              <b>Universal Impacts:</b>
              <br>
              <span v-show="newReport.riskType" v-for="(impacts,iidx) in newReport.riskType.UniversalImpacts" :key="iidx+$lodash.uniqueId()">
                {{impacts}} <br>
              </span>
            </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="reportDialog = false,clearReport()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="reportDialog = false,saveReport()"
          >
            Send Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changeUserDeptDialog"
      max-width="600px"
      persistent
    >
      <v-card class="py-2">
        <v-card-title class="headline">
          <v-icon class="mr-2">mdi-office-building-outline</v-icon> Change Department / Section to View
          <v-spacer></v-spacer>
          <v-btn icon @click="changeUserDeptDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            :items="userDepartmentSections"
            v-model="changeDept"
            label="Select Department/Section to view."
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="changeUserDeptDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="changeUserDeptDialog = false,changeStoreDept()"
          >
            Change Department / Section
          </v-btn>
        </v-card-actions>        
      </v-card>
    </v-dialog>
      
    <v-main class="grey lighten-5" style="padding: 0px;">
      <router-view />
    </v-main>

  </v-app>
</template>

<script>
import { firebaseAuth } from '../plugins/firebase';
export default {
  name: 'App',

  data: () => ({
    reportDialog: false,
    newReport:{
      riskType: null,
      description: '',
    },
    user: null,
    drawer: null,
    userDepartmentSections: [],
    changeDept: null,
    changeUserDeptDialog: false,
    links: [
      ['mdi-view-dashboard', 'Dashboard', '/', ''],
      ['mdi-format-list-text', 'ORMP', '/viewallcoverage', ''],
      ['mdi-window-closed', 'PRMP', '/viewallprmpcoverage'],
      ['mdi-view-quilt', 'IRA', '/viewalliracoverage', 'RMT'],
      ['mdi-trello', 'IRA Assessment', '/viewassessment', 'RMT'],
      ['mdi-web', 'Risk Universe', '/riskuniverse', ''],
      ['mdi-plus', 'Add New Risk', '/addnewrisk', ''],
      ['mdi-chart-box', 'RAEME', '/RAEME', ''],
      ['mdi-chart-bar-stacked', 'KRI', '/viewallkricoverage', ''],
      ['mdi-table-large', 'Risk Map and Movement', '/riskmap', ''],
      ['mdi-file-cabinet', 'File Maintenance', '/filemaintenance' , 'RMT'],
      ['mdi-email-send', 'Send Notifications', '/sendnotifications' , 'RMT'],
    ],
  }),
  firestore(){
    return {
      UserAccounts: this.$db.collection('UserAccounts'),
      Risk: this.$db.collection('Risk'),
      ReportedRisk: this.$db.collection('ReportedRisk').orderBy('timestamp','desc'),
      RiskAlerts: this.$db.collection('RiskAlerts').orderBy('timestamp','desc'),
      ActivityLogs: this.$db.collection('ActivityLogs').orderBy('timestamp','desc').limit(10),
    }
  },
  mounted(){
      let self = this
      firebaseAuth.onAuthStateChanged(function(user) {
        if (user) {   
          // console.log(user,'user')
          self.user = user

        } else {
          console.log('no user')
          self.user = null
        }   
      })
  },
  computed:{
    returnLengthNotSeenAlert(){
      let userDept = this.$store.getters['useraccount/isAuthenticated'].department
      console.log(userDept,'userDept');
      if(userDept == 'RISK MANAGEMENT TEAM') return []
      return this.RiskAlerts.filter(a=>{
        return a.isVerified == undefined || a.isVerified == false && this.$lodash.includes(a.Department,userDept)
      })
    },
    returnLogs(){
      let user = this.$store.getters['useraccount/isAuthenticated']
      if(user.department !== 'RISK MANAGEMENT TEAM'){
        return this.ActivityLogs.filter(a=>{
          return a.department == user.department
        })
      }

      return this.ActivityLogs
    },
    returnLengthNotSeenNotifs(){
      let user = this.$store.getters['useraccount/isAuthenticated']
      if(user.department !== 'RISK MANAGEMENT TEAM'){
        return this.returnLogs.filter(a=>{
          return a.isVerified == undefined
        }).length
      } else {
        return this.returnLogs.filter(a=>{
          return a.isVerified == undefined
        }).length        
      }

    },
    returnLengthNotSeen(){
      return this.ReportedRisk.filter(a=>{
        return a.isVerified == undefined
      }).length
    },
    returnPath(){
      let arry = this.$route.path.split('/')
      return arry[1]
    },
    returnStoreUser(){
      return this.$store.getters['useraccount/isAuthenticated']
    },
    returnUserDetails(){
      if(!this.UserAccounts) return null
      if(!this.user) return null

      let filter = this.UserAccounts.filter(a=>{
        return this.user.email == a.emailAdd
      })[0]

      return filter == undefined ? null : filter
    },
    mapRisk(){
      return this.Risk.map(a=>{
        return {
          text: a.RiskName,
          value: a
        }
      })
    }
  },
  methods:{
    changeUserDepartmentSection(){
      let user = this.returnUserDetails
      let depts = user.departmentSections
      this.userDepartmentSections = depts
      this.changeUserDeptDialog = true     
    },
    changeStoreDept(){
      console.log(this.changeDept,'userDepartmentSections')
      this.$store.commit('useraccount/changeDept', this.changeDept)
    },
    openMenuNotifSeen(){
      if(this.returnLogs.length == 0) return
      let notifs = this.returnLogs.slice(0,3)
      notifs.forEach(a=>{
        if(a.deptSeen === undefined || a.RMTSeen === undefined){
          this.updateNotif(a)
        }
      })
    },
    updateNotif(notif){
      let user = this.$store.getters['useraccount/isAuthenticated']
      if(user.department !== 'RISK MANAGEMENT TEAM'){
          this.$db.collection('ActivityLogs').doc(notif['.key']).update({
              deptSeen: true
          })
      } else {
          this.$db.collection('ActivityLogs').doc(notif['.key']).update({
              RMTSeen: true
          })            
      } 
    },
    signOut(){
      let self = this
      firebaseAuth.signOut().then(function() {
        // Sign-out successful.
        console.log('sign out')
        self.user = null
        self.$store.commit('useraccount/setDashboardUser', null)
        self.$router.push('/login')
      }).catch(function(error) {
        // An error happened.
        console.log(error,'err')
      });        
    },
    async saveReport(){
      if(this.newReport.riskType == null || this.newReport.description == ''){
        this.$dialog.error({
            text: `Please Complete all the needed fields. Thank you`,
            title: `Incomplete Report`
        })
        this.reportDialog = true
        return
      }

      if(this.newReport.riskType !== null && this.newReport.description !== ''){
        const confirm = await this.$dialog.confirm({
            text: `Do you want to send this report? `,
            title: `Confirm Sending of Report`
        })
        if(!confirm){
          this.reportDialog = true
          return
        }   
        
        if(confirm){
          let newReport = {...this.newReport.riskType}
          newReport.riskKey = newReport['.key']
          delete newReport['.key']
          newReport.reportDescription = this.newReport.description
          newReport.userID = this.user.email
          newReport.timestamp = this.$firebase.firestore.FieldValue.serverTimestamp()
          this.$db.collection('ReportedRisk').add(newReport)
          .then(()=>{
              this.$dialog.notify.success(`Success Sending Risk Report`, {
                  position: 'bottom-right',
                  timeout: 3000
              }) 
              this.newReport = {
                riskType: null,
                description: '',
              }            
          }).catch(err=>{
              this.$dialog.notify.success(`Error : ${err}`, {
                  position: 'bottom-right',
                  timeout: 3000
              })              
          })
        }
      }



    },
    async clearReport(){
      if(this.newReport.riskType !== null || this.newReport.description !== ''){
        const confirm = await this.$dialog.confirm({
            text: `Do you want to cancel this reporting? This will remove all the report info you entered.`,
            title: `Confirm Cancel`
        })
        if(!confirm){
          this.reportDialog = true
          return
        } 
      }

      this.newReport = {
        riskType: null,
        description: '',
      }
    },
    returnAccess(access){
      if(access == 'RMT' && this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM'){
        return false
      } 
        return true
      
    }
  }
}
</script>
<style scoped>
  .rtl {
    direction: rtl;
  }
</style>
